<template>
  <div v-if="mobile" style="position:relative; top:-64px;">
    <!-- top:-64px is the app bar height -->
    <!-- keep the top position as Alan still wants to use video in mobile homepage -->
    <div class="hero-video-ctnr cust-hero-ctnr" style="width:100%; height:auto;">
      <video style="width:100%; display: block;" muted loop autoplay>
        <source :src="hero_mobile" type="video/mp4" />
      </video>
      <div class="cust-hero-item h-100 d-flex flex-column align-left justify-space-between">
        <!-- overlaying stuff -->
        <div></div>
        <div class="ma-7">
          <v-icon v-if="isVideoMuted" icon="mdi-volume-off" color="grey-lighten-3" size="x-large" @click="toggle_hero_audio"></v-icon>
          <v-icon v-else icon="mdi-volume-high" color="grey-lighten-3" size="x-large" @click="toggle_hero_audio"></v-icon>
        </div>
      </div>
    </div>
    
    <div class="pa-7 text-white">
      <div class="py-5 mobile-home-header-div">
        <p class="mobile-home-header-em">Transforming Careers</p>
        <p class="mobile-home-header">Transcending Boundaries</p>
      </div>
      <div class="pb-15 mobile-home-header-text text-white">
        <p>
          We are motivated by our desire to bring about a positive change in the world. Whether we do it by connecting talent to the right position, or by contributing towards helping the underprivileged - we do our best to play our part.
        </p>
      </div>
    </div>

    <div class="px-7 pt-7 cust-bg-color-white">
      <div class="py-7 mobile-home-ourbrands-header-div">
        <strong class="mobile-home-ourbrands-header-em">OUR BRANDS </strong>
        <strong class="mobile-home-ourbrands-header">ACROSS ASIA</strong>
      </div>
      <div class="pb-0 mobile-home-ourbrands-header-text">
        <p class="mobile-paragraph-spacing">The Inter Island Group stands as a premier solutions provider keenly attuned to the distinct and individualized recruitment, manpower and human resource needs of every business.</p>
        <p class="mobile-paragraph-spacing">Our extensive portfolio encompasses a wide array of services, ranging from recruitment and human resources to hospitality, telco, and beyond. Our global network boasts hundreds of employees across Asia, driving innovation and excellence in every sector we touch. Whether it's sourcing skilled professionals, managing staffing levels, or implementing training and development programs, our comprehensive approach to manpower solutions enables businesses to thrive in today's competitive landscape.</p>
        <p class="mobile-paragraph-spacing">With a vast global network spanning across Asia, our hundreds of dedicated employees continually spearhead innovation and uphold standards of excellence across every sector we engage with.</p>
      </div>
    </div>

    <v-container fluid class="px-0 cust-bg-color-white">
      <v-row no-gutters>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('interisland manpower')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-manpower-cl.png"></v-img>
          </div>
        </v-col>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('interisland mgmt')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-management-cl.png"></v-img>
          </div>
        </v-col>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('interisland consulting')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/interisland-consulting-color.png"></v-img>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('iTravels')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-itravels-cl.png"></v-img>
          </div>
        </v-col>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('Manpower Solution')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/manpower_solution.png"></v-img>
          </div>
        </v-col>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('iConnect')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-iconnect-cl.png"></v-img>
          </div>
        </v-col>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('ASEANet Distributor')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/Aseanet_distributor.png"></v-img>
          </div>
        </v-col>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('ASEANet Manpower')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/Aseanet-manpower.png"></v-img>
          </div>
        </v-col>
        <v-col>
          <div class="pa-7 mobile-home-brands-logo-div" @click="dynamic_routing('iCAFE')">
            <v-img class="mobile-home-brands-logo" title="Discover our Brands" src="../assets/Logos/icafe_logo.png"></v-img>
          </div>
        </v-col>
      </v-row>
    </v-container>

    <div class="px-7 pb-10 cust-bg-color-white d-flex justify-center">
      <v-btn class="btn-white" @click="router.push(`/OurBrands`)">DISCOVER OUR BRANDS</v-btn>
    </div>

    <!-- -------------------------------------------------- -->

    <v-carousel style="height:auto;" show-arrows>
      <v-carousel-item eager>
        <v-sheet class="d-flex flex-column cust-bg-color-purple text-white">
          <div class="cust-bg-color-purple">
            <v-img src="../assets/Images/Mobile_Scoller1_Image.png"></v-img>
          </div>
          <div class="pa-7 text-white d-flex flex-column align-start">
            <div class="py-7">
              <strong class="mobile-home-cp-header-em">EXPERT MANPOWER AND RECRUITMENT SOLUTIONS</strong>
            </div>
            <div>
              <p class="mobile-home-cp-text">
                Our expertise in manpower and recruitment services empowers businesses to thrive in today's competitive landscape. With years of experience and deep industry knowledge, we offer comprehensive solutions tailored to meet each client's unique staffing needs. We identify candidates who not only possess the required skills and qualifications but also align with our clients' organizational culture and values.
              </p>
            </div>
            <button class="my-10 btn-purple align-self-center" @click="router.push(`/CorpProfile`)">CORPORATE PROFILE</button>
          </div>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item eager>
        <v-sheet class="d-flex flex-column cust-bg-color-purple text-white">
          <div class="cust-bg-color-purple">
            <v-img width="100%" src="../assets/Images/Mobile_Scoller2_image.png"></v-img>
          </div>
          <div class="pa-7 text-white d-flex flex-column align-start">
            <div class="py-7">
              <strong class="mobile-home-cp-header-em">STREAMLINING HR OPERATIONS</strong>
            </div>
            <div>
              <p class="mobile-home-cp-text">
                We specialize in HR outsourcing and solutions designed to streamline operations and drive organizational efficiency. We support company businesses from A to Z, and enable businesses to offload administrative burdens, allowing them to focus on core activities and strategic initiatives.
              </p>
            </div>
            <button class="my-10 btn-purple align-self-center" @click="router.push(`/CorpProfile`)">CORPORATE PROFILE</button>
          </div>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item eager>
        <v-sheet class="d-flex flex-column cust-bg-color-purple text-white">
          <div class="cust-bg-color-purple">
            <v-img width="100%" src="../assets/Images/20May2024_Mobile_Scroller3.png"></v-img>
          </div>
          <div class="pa-7 text-white d-flex flex-column align-start">
            <div class="py-7">
              <strong class="mobile-home-cp-header-em">ELEVATING HOSPITALITY EXCELLENCE</strong>
            </div>
            <div>
              <p class="mobile-home-cp-text">
                Our commitment to hospitality extends beyond serving the perfect cup of coffee to providing seamless travel experiences for our customers. With our travel agency services, we specialize in booking flights, accommodations, and travel packages tailored to meet the unique preferences of every individual.
              </p>
            </div>
            <button class="my-10 btn-purple align-self-center" @click="router.push(`/CorpProfile`)">CORPORATE PROFILE</button>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>


    <!-- -------------------------------------------------- -->



    <!-- featured initiatives -->
    <div class="pa-7 cust-bg-color-white">
      <div class="d-flex py-5 justify-space-between align-start">
        <div class="mobile-our-initiative-header-div">
          <strong class="mobile-our-initiative-header-em">FEATURED</strong>
          <strong class="mobile-our-initiative-header"><br>INITIATIVES</strong>
        </div>
        <v-btn class="btn-white" @click="router.push(`/OurInitiatives#initiative-options`)">VIEW ALL</v-btn>
      </div>
    </div>
      
    <template v-for="(post, post_idx) in featured_posts" :key="post_idx">
      <v-divider class="px-7 border-opacity-80"></v-divider>
      <div class="pa-7" :class="post_idx == selected_post_idx ? 'cust-bg-color-purple' :'cust-bg-color-white'">
        <!-- cover pic -->
        <div class="py-2">
          <img width="100%" :src="post.gallery[post.selected_pic_idx]">
        </div>
        <!-- card -->
        <div class="d-flex flex-column mobile-our-initiative-post-text" :class="{'text-white': post_idx == selected_post_idx}">
          <div class="d-flex justify-space-between">
            <p><b>{{post.category}}</b>, {{post.subtitle1}}</p>
            <!-- arrow icon -->
            <v-icon v-if="post_idx == selected_post_idx" icon="mdi-arrow-left" class="font-teal" @click="selected_post_idx = null"></v-icon>
            <v-icon v-else icon="mdi-arrow-right" @click="{selected_post_idx = post_idx; post.selected_pic_idx = 0}"></v-icon>
          </div>
          <div class="my-4 d-flex justify-start align-center">
            <strong :class="{'font-teal': post_idx == selected_post_idx}" style="text-transform: uppercase;">{{post.subject}}</strong>
          </div>
        </div>
        <!-- gallery -->
        <v-container v-if="post_idx == selected_post_idx" class="w-100 pa-0" fluid>
          <v-row>
            <v-col cols="4" v-for="(pic_url, pic_idx) in post.gallery" :key="pic_idx">
              <div @click="post.selected_pic_idx = pic_idx">
                <img width="100%" :src="pic_url" alt="">
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </template>

  </div>

  <!------------------ mobile responsive divider ------------------>

  <div v-if="!mobile" class="d-flex flex-column w-100 justify-start" style="position:relative; top:-64px;">
    <!-- top:-64px is the app bar height -->
    <div class="cust-hero-ctnr c-header-section">
      <!-- hero video -->
      <div class="hero-video-ctnr" style="width:100%; height:auto;aspect-ratio: 640/340; ">
        <!-- single video with sound -->
        <video style="width:100%; display: block;" muted loop autoplay>
          <source :src="hero_desktop" type="video/mp4" />
        </video>
        <!-- ----------------- multiple videos play in sequence but load at the same time ---------------------- -->
        <!-- <video style="width:100%; display: block;" muted preload>
          <source :src="hero_mp4" type="video/mp4" />
        </video>
        <video style="width:100%; display: none;" muted preload>
          <source :src="hero_mp4_2" type="video/mp4" />
        </video>
        <video style="width:100%; display: none;" muted preload>
          <source :src="hero_mp4_3" type="video/mp4" />
        </video>
        <video style="width:100%; display: none;" muted preload>
          <source :src="hero_mp4_4" type="video/mp4" />
        </video>
        <video style="width:100%; display: none;" muted preload>
          <source :src="hero_mp4_5" type="video/mp4" />
        </video> -->
        <!-- ----------------- multiple videos play in sequence but load at the same time ---------------------- -->
      </div>
      <!-- overlaying text -->
      <div class="cust-hero-item ma-0 pa-0 c-prevent-select" style="background-color: rgba(0,0,0,0.4);">
        <div class="d-flex h-100 py-15 mx-15 flex-column justify-space-between align-start" style="width:fit-content; z-index:1000">
          <div></div>
          <div class="align-self-start c-home-header-div">
            <strong class="c-home-header-em">Transforming Careers</strong><br>
            <strong class="c-home-header">Transcending Boundaries</strong>
          </div>
          <v-icon v-if="isVideoMuted" icon="mdi-volume-off" color="grey-lighten-3" size="x-large" @click="toggle_hero_audio"></v-icon>
          <v-icon v-else icon="mdi-volume-high" color="grey-lighten-3" size="x-large" @click="toggle_hero_audio"></v-icon>
        </div>
        
      </div>
    </div>

    <v-sheet class="cust-bg-color-white d-flex flex-column">

      <div class="pa-15">
        <div class="c-home-brands-title-div">
          <strong class="c-home-brands-title-em">OUR BRANDS</strong><br>
          <strong class="c-home-brands-title">ACROSS ASIA</strong>
        </div>
        <p class="mt-4">The Inter Island Group stands as a premier solutions provider keenly attuned to the distinct and individualized recruitment, manpower and human resource needs of every business.</p>
        <p class="mt-4">Our extensive portfolio encompasses a wide array of services, ranging from recruitment and human resources to hospitality, telco, and beyond. Our global network boasts hundreds of employees across Asia, driving innovation and excellence in every sector we touch. Whether it's sourcing skilled professionals, managing staffing levels, or implementing training and development programs, our comprehensive approach to manpower solutions enables businesses to thrive in today's competitive landscape.</p>
        <p class="mt-4">With a vast global network spanning across Asia, our hundreds of dedicated employees continually spearhead innovation and uphold standards of excellence across every sector we engage with.</p>
      </div>

      <div class="px-15 pb-15 d-flex justify-space-between">
        <v-container fluid class="mr-15">
          <v-row>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('interisland manpower')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-manpower-cl.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('interisland mgmt')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-management-cl.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('interisland consulting')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/interisland-consulting-color.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('iTravels')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-itravels-cl.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('Manpower Solution')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/manpower_solution.png"></v-img>
              </div>
            </v-col>
            
          </v-row>
          <v-row>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('iConnect')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/b-iconnect-cl.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('ASEANet Distributor')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/Aseanet_distributor.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('ASEANet Manpower')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/Aseanet-manpower.png"></v-img>
              </div>
            </v-col>
            <v-col>
              <div class="px-0 py-7 c-home-brands-logo-div" @click="dynamic_routing('iCAFE')">
                <v-img width="150" class="c-home-brands-logo" title="Discover our Brands" src="../assets/Logos/icafe_logo.png"></v-img>
              </div>
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-container>
        <button class="rounded-pill mt-15 ml-15 px-6 btn-w-anime" style="text-wrap: nowrap;" @click="router.push(`/OurBrands`)">DISCOVER OUR BRANDS</button>
      </div>
    </v-sheet>

    <v-carousel style="height:auto;" show-arrows>
      <v-carousel-item eager>
        <v-sheet class="d-flex cust-bg-color-purple text-white">
          <div class="w-50 d-flex flex-column justify-space-around align-start" style="padding:80px;">
            <div></div>
            <div>
              <strong class="c-home-cp-title">EXPERT MANPOWER AND RECRUITMENT SOLUTIONS</strong>
              <p>
                Our expertise in manpower and recruitment services empowers businesses to thrive in today's competitive landscape. With years of experience and deep industry knowledge, we offer comprehensive solutions tailored to meet each client's unique staffing needs. We identify candidates who not only possess the required skills and qualifications but also align with our clients' organizational culture and values.
              </p>
            </div>
            <button class="btn-blue btn-w-anime" @click="router.push(`/CorpProfile`)">CORPORATE PROFILE</button>
          </div>
          <div class="w-50">
            <v-img src="../assets/Images/Desktop_scroller_image1.png"></v-img>
          </div>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item eager>
        <v-sheet class="d-flex cust-bg-color-purple text-white">
          <div class="w-50 d-flex flex-column justify-space-around align-start" style="padding:80px;">
            <div></div>
            <div>
              <strong class="c-home-cp-title">STREAMLINING HR OPERATIONS</strong>
              <p>
                We specialize in HR outsourcing and solutions designed to streamline operations and drive organizational efficiency. We support company businesses from A to Z, and enable businesses to offload administrative burdens, allowing them to focus on core activities and strategic initiatives.
              </p>
            </div>
            <button class="btn-blue btn-w-anime" @click="router.push(`/CorpProfile`)">CORPORATE PROFILE</button>
          </div>
          <div class="w-50">
            <v-img src="../assets/Images/Desktop_scroller_image2.png"></v-img>
          </div>
        </v-sheet>
      </v-carousel-item>
      <v-carousel-item eager>
        <v-sheet class="d-flex cust-bg-color-purple text-white">
          <div class="w-50 d-flex flex-column justify-space-around align-start" style="padding:80px;">
            <div></div>
            <div>
              <strong class="c-home-cp-title">ELEVATING HOSPITALITY EXCELLENCE</strong>
              <p>
                Our commitment to hospitality extends beyond serving the perfect cup of coffee to providing seamless travel experiences for our customers. With our travel agency services, we specialize in booking flights, accommodations, and travel packages tailored to meet the unique preferences of every individual.
              </p>
            </div>
            <button class="btn-blue btn-w-anime" @click="router.push(`/CorpProfile`)">CORPORATE PROFILE</button>
          </div>
          <div class="w-50">
            <v-img src="../assets/Images/Desktop_scroller_image3.png"></v-img>
          </div>
        </v-sheet>
      </v-carousel-item>
    </v-carousel>

    <v-sheet class="bg-white pt-15 d-flex flex-column">
      <div class="mb-4 mx-15 d-flex justify-space-between">
        <div class="c-home-featured-title-div">
          <strong class="c-home-featured-title-em">FEATURED</strong><br>
          <strong class="c-home-featured-title">INITIATIVES</strong>
        </div>
        <button class="btn-blue btn-w-anime" @click="router.push(`/OurInitiatives#initiative-options`)">VIEW ALL</button>
      </div>

      <template v-for="(post, post_idx) in featured_posts" :key="post_idx">
        <v-divider class="mx-15 border-opacity-100"></v-divider>
        <div class="py-10 ma-0 d-flex flex-column c-home-feature-post">

          <div class="d-flex px-15">
            <!-- featured post component -->
            <div class="c-home-feature-post-picbox">
              <v-img cover :src="post.gallery[post.selected_pic_idx]"></v-img>
            </div>
            <div class="ml-4 mr-8 d-flex flex-column c-home-feature-post-txtbox">
              <!-- card -->
              <div class="d-flex justify-start">
                <p><b>{{post.category}}</b>, {{post.subtitle1}}</p>
                <!-- <p>{{post.subtitle2}}</p> -->
              </div>
              <div class="my-4">
                <strong>{{post.subject}}</strong>
                <!-- <p class="c-home-feature-post-summary">{{post.summary}}</p>               -->
              </div>
            </div>
            <v-icon v-if="post_idx == selected_post_idx" icon="mdi-arrow-left" class="font-teal" @click="selected_post_idx = null"></v-icon>
            <v-icon v-else icon="mdi-arrow-right" @click="{selected_post_idx = post_idx; post.selected_pic_idx = 0}"> </v-icon>
          </div>

          <div class="d-flex flex-wrap px-12" v-if="post_idx == selected_post_idx">
            <template v-for="(pic_url, pic_idx) in post.gallery" :key="pic_idx">
              <div class="c-home-feature-post-gal-img-div" @click="post.selected_pic_idx = pic_idx">
                <img width="100%" :src="pic_url" alt="">
              </div>
            </template>
          </div>
          
        </div>
        
      </template>

    </v-sheet>

  </div>
</template>

<script>
import hero_mobile from "../assets/videos/FINAL_Mobile_IIG_Video_reduced.mp4"
import hero_desktop from "../assets/videos/FINAL_Desktop_IIG_Video_reduced.mp4"
// ----------- integration of Lenis for smooth scrolling ----------------
// import Lenis from '@studio-freight/lenis'
// const lenis = new Lenis({
//   lerp: 0.1,
//   smooth: true,
// })
// const scrollFn = (time) => {
//     lenis.raf(time);
//     requestAnimationFrame(scrollFn);
// };
// requestAnimationFrame(scrollFn);
// ----------- integration of GSAP and Lenis ----------------
// ScrollTrigger plugin will allow us to animate an element when it enters or exits the viewport.
import { gsap } from "gsap";    
import { ScrollTrigger } from "gsap/ScrollTrigger";
gsap.registerPlugin(ScrollTrigger);
// lenis.on('scroll', ScrollTrigger.update) // this line causes scrolltrigger to mis fire events.
// gsap.ticker.add((time)=>{
//   lenis.raf(time * 1000)
// })
// gsap.ticker.lagSmoothing(0)
// ------------------------------------------------
import { onBeforeRouteLeave } from "vue-router";
import { onMounted, ref } from 'vue';
import { useDisplay } from 'vuetify'
import { useRouter } from "vue-router";
import { getDocs, collection, query, where, orderBy } from "firebase/firestore";
import { fb_firestoreDB } from "@/main";
export default {
  name: "HomeView",
  setup(){
    // vuetify display mobile resonsive
    const {mobile} = useDisplay()

    // dynamic logo navigation
    const router = useRouter()
    const dynamic_routing = (idx) => {
      router.push("/OurBrands?selected_brand=" + idx.toString())
    }

    // // --------------------------- hero videos trick ---------------------------
    // onMounted(() => {
    //   /* There are a list of 5 videos to play from the 1st video, and then loop through the sequence. Only subject to non-mobile browser */
    //   if(!mobile.value){
    //     // console.log("show starts")
    //     const hero_videos = document.querySelectorAll(".hero-video-ctnr > video")
    //     hero_videos[0].oncanplaythrough = (event) => {
    //       // console.log("Video 1 ready")
    //       hero_videos[0].play()
    //     }
    //     hero_videos[0].onended = (event) => {
    //       // console.log("Video 1 finished")
    //       hero_videos[0].style.display = "none"
    //       hero_videos[1].style.display = "block"
    //       hero_videos[1].play()
    //     }
    //     hero_videos[1].onended = (event) => {
    //       // console.log("Video 2 finished")
    //       hero_videos[1].style.display = "none"
    //       hero_videos[2].style.display = "block"
    //       hero_videos[2].play()
    //     }
    //     hero_videos[2].onended = (event) => {
    //       // console.log("Video 3 finished")
    //       hero_videos[2].style.display = "none"
    //       hero_videos[3].style.display = "block"
    //       hero_videos[3].play()
    //     }
    //     hero_videos[3].onended = (event) => {
    //       // console.log("Video 4 finished")
    //       hero_videos[3].style.display = "none"
    //       hero_videos[4].style.display = "block"
    //       hero_videos[4].play()
    //     }
    //     hero_videos[4].onended = (event) => {
    //       // console.log("Video 5 finished")
    //       hero_videos[4].style.display = "none"
    //       hero_videos[0].style.display = "block"
    //       hero_videos[0].play()
    //     }
    //   }
    // })

    // -------- load featured posts ---------
    const featured_posts = ref([])
    const load_featured_posts = async () => {
      featured_posts.value = []
      const querySnapShot = await getDocs(
        query(collection(fb_firestoreDB, "posts"), where("featured", "==", true), orderBy("post_date", "desc")) 
      );
      querySnapShot.forEach((doc) => {
        // doc.data() is never undefined for query doc snapshots
        featured_posts.value.push(doc.data());
      });
      // update gallery with cover url as the leading photo and init selected_pic_idx
      featured_posts.value.forEach((post) => {
        post.gallery = [].concat([post.cover_url], post.gallery)
        post.selected_pic_idx = 0
      })
      // order by post_date descending
      // featured_posts.value.sort((a,b) => b.post_date - a.post_date)
      // console.log(featured_posts.value)
    }
    load_featured_posts()
    // --------------------------------------
    

    let tween_app_bar
    onMounted(() =>{
      // ----------------- app bar animation that is only applicable to specific route --------------------------
      const el = document.querySelector("#id-app-bar")
      // console.log(el)
      tween_app_bar = gsap.timeline({
        scrollTrigger: {
          id: "app_bar_trigger",
          invalidateOnRefresh: true,
          start: "0",
          end: "400",
          scrub: true,
          // markers: true,
          // onRefresh: () => {
          //   // These lenis methods works on smooth scroll after resizing, // BUG: currently resizing will cause this app bar become dead white. with invalidateOnRefresh, this looks better. 
          //   // lenis.stop()
          //   // lenis.start()
          //   // console.log("scrollTrigger refreshed...............")
          // }
        }
      }).fromTo(el, {
        backgroundColor: 'transparent'
      }, {
        ease:'power3.in',
        backgroundColor: '#583480',
      })
      // ------------------------------------------------------------------------------------------------------
    })

    const isVideoMuted = ref(true)
    const toggle_hero_audio = ()=>{
      const videoElem = document.querySelector(".hero-video-ctnr > video")
      isVideoMuted.value = !isVideoMuted.value
      videoElem.muted = isVideoMuted.value
    }

    let video_trigger
    onMounted(() => {
      // ---------------- video play / pause when in / out of viewport -----------------
      const videoElem = document.querySelector(".hero-video-ctnr > video")
      const videoCtnr = document.querySelector(".hero-video-ctnr")
      videoElem.oncanplaythrough = (event) => {
        // video is ready
        video_trigger = ScrollTrigger.create({
          trigger: videoCtnr,
          start: "bottom bottom",
          end: "bottom 10%",
          onToggle: (self) => {
            if(self.isActive){
              videoElem.play()
            } else {
              videoElem.pause()
            }
          }
        })
      }      
    })

    onBeforeRouteLeave((to, from) => {
      // clean up GSAP animations and css with revert()
      // console.log("onBeforeRouteLeave triggered.")
      tween_app_bar.revert()
      video_trigger.kill()
    })

    const selected_post_idx = ref(null)

    return {
      mobile,
      router,
      dynamic_routing,
      // 
      isVideoMuted,
      toggle_hero_audio,
      hero_mobile,
      hero_desktop,
      // hero_mp4,
      // hero_mp4_2,
      // hero_mp4_3,
      // hero_mp4_4,
      // hero_mp4_5,
      //
      featured_posts,
      selected_post_idx,
    }
  }
}
</script>
